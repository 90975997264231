import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { darken } from 'polished'
import { ComponentProps } from 'react'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { ArticleGraphic } from '@/features/article-sections'
import { Seal } from '@/features/brearley-logo'
import { DatoLink } from '@/features/dato-link'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { MissionArticleHeroImages } from './MissionArticleHeroImages'

interface Props extends ComponentProps<'article'> {
  data?: Queries.MissionArticleFragment | null
  layout: 'PAGE' | 'LIGHTBOX'
}

export const MissionArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      position: relative;
      padding: var(--border-width, 1.5rem);
      padding-right: var(--margin);
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-column-gap: var(--gtr-54);
      background: #fff;
      ${mq().s} {
        grid-template-columns: 1fr;
      }
      ${layout === 'PAGE' &&
      css`
        --border-width: 1.5rem;
        border: var(--border-width) solid ${colors.red};
        border-top-width: 0;
        border-bottom-width: var(--row-36);
        ${mq().s} {
          border-width: var(--gtr);
        }
      `}
    `,
    images: css`
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      align-self: flex-start;
      position: relative;
      display: grid;
      grid-gap: var(--gtr);
      ${mq().ms} {
        grid-column: 1 / -1;
        grid-row: auto;
        grid-template-columns: repeat(${data?.heroImage?.length}, 1fr);
      }
    `,
    text: css`
      grid-column: 2 / 3;
      margin-bottom: 3em;
      position: relative;
      h1 {
        font-family: var(--body);
        font-size: var(--fs-16);
        font-weight: 700;
        text-transform: uppercase;
        color: ${colors.red};
        margin: 2em 0 1em;
        line-height: 1;
      }
      h2 {
        font-size: var(--fs-72);
        text-transform: uppercase;
        margin: 0 0 0.25em;
        line-height: 1;
        ${mq().s} {
          font-size: var(--fs-60);
        }
      }
      p {
        line-height: 1.75;
        max-width: 70ch;
      }
      ${mq().ms} {
        grid-column: 1 / -1;
      }
    `,
    links: css`
      grid-column: 2 / 3;
      border-top: 3px solid #ddd;
      margin-bottom: 1em;
      position: relative;
      h3 {
        font-size: var(--fs-30);
        text-transform: uppercase;
        margin: 0.5em 0;
      }
      > div {
        display: block;
        gap: 1em;
        font-size: var(--fs-15);
        margin-bottom: 1em;
        a {
          display: block;
          text-transform: uppercase;
          color: ${colors.red};
          text-decoration: underline;
          text-underline-offset: 0.125em;
          @media (hover: hover) {
            &:hover {
              color: ${darken(0.15, colors.red)};
            }
          }
        }
      }
      ${mq().ms} {
        grid-column: 1 / -1;
      }
    `,
    seal: css`
      ${absoluteFill}
      overflow: hidden;
      z-index: 0;
      img {
        position: absolute;
        top: var(--margin);
        left: 75%;
        transform: translateX(-25%);
        opacity: 0.05;
        width: max(50%, 90svh);
        height: auto;
      }
    `,
    backButton: css`
      position: sticky;
      z-index: 9;
      top: 0;
      right: 0;
      display: flex;
      padding: 1rem;
      color: #fff;
      background: ${colors.red};
      transition:
        color 200ms ease,
        transform 300ms ease-out,
        opacity 450ms ease,
        top 500ms ease;
      svg {
        width: 3rem;
        height: 3rem;
        overflow: visible;
        transition: transform 500ms ${bezier.bounce};
        transform: translate3d(-0.2rem, 0, 0);
        path {
          fill: none;
          stroke: currentColor;
          stroke-width: 3;
        }
        ${mq().ms} {
          width: 2rem;
          height: 2rem;
          path {
            stroke-width: 4;
          }
        }
      }
      @media (hover: hover) {
        &:hover {
          svg {
            transform: translate3d(-0.5rem, 0, 0);
            ${mq().ms} {
              transform: translate3d(-0.333rem, 0, 0);
            }
          }
        }
      }
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <div css={styles.seal}>
        <Seal
          color={colors.red}
          aria-hidden
        />
      </div>
      <MissionArticleHeroImages data={data?.heroImage} />
      <div css={styles.text}>
        <h1>{data?.eyebrowText}</h1>
        <h2>{data?.heading}</h2>
        <DatoStructuredText
          data={data?.body}
          renderBlock={({ record }) => <ArticleGraphic data={record} />}
        />
      </div>
      {data?.goFurther?.length ? (
        <div css={styles.links}>
          <h3>Go Further:</h3>
          <div>
            {data?.goFurther.map(link => (
              <DatoLink
                data={link}
                key={link?.id}
              />
            ))}
          </div>
        </div>
      ) : null}
    </article>
  )
}

export const MissionArticleQuery = graphql`
  fragment MissionArticle on DatoCmsMissionArticle {
    __typename
    id: originalId
    eyebrowText
    heading
    heroImage {
      gatsbyImageData(
        width: 720
        sizes: "(max-width: 540px) 90vw, 50vw"
        imgixParams: { q: 65 }
      )
      title
      ...ImageFocalData
    }
    body {
      value
      blocks {
        ...ArticleGraphic
      }
    }
    goFurther {
      __typename
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsPageLink {
        ...PageLink
      }
    }
    seoMetaTags {
      tags
    }
    slug
  }
`
